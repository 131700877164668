:root {
  /* Font */
  --font-primary: 'Fira Sans';
  --font-light: 'gilroy-light';
  --font-header: 'gilroy';

  --h1-size: 54px;
  --h2-size: 32px;
  --h3-size: 24px;
  --h4-size: 21px;
  --p-size: 14px;

  /* Colors */
  --bg: linear-gradient(180deg, #ebf3fa 9.85%, #dde7f3 53.92%, #e6f0f9 100%);
  --color-primary: #31454a;
  --color-secondary: #31454a;
  --color-tertiary: #afa8ba;
  --p-color: #31456a;
  --hover-btn: #dae3ec;
}
