.container {
  position: relative;
  display: inline-block;

  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 46%
  ); */
}

.content {
  position: absolute;
}

.h {
  margin: 0px 0 0 10px;
  /* color: aliceblue; */
  /* text-shadow: 2px 3px 10px #8d8d8d; */
  /* -webkit-text-stroke: 0.2px #979797; */
}

.p {
  margin: 5px 0 5px 10px;
  /* color: aliceblue; */
  /* text-shadow: 1px 1px 5px #bdbdbd; */
  -webkit-text-stroke: 0.2px #616161;
  opacity: 0.9;
}

.img {
  width: 500px;
}

.business {
  background-position: 300px;
}

@media all and (max-width: 1300px) {
  .business {
    background-position: 100px;
  }
}

@media all and (max-width: 768px) {
  .business {
    background-position: 200px;
  }
}
