.container {
  width: 80%;
  margin: 0px auto;
}

@media all and (max-width: 1500px) {
  .container {
    width: 95%;
    margin: 40px auto;
  }
}

.featuresContainer {
  /* padding: 50px; */
}

@media all and (max-width: 903px) and (min-width: 380px) {
  .featuresContainer {
    margin-top: 120px;
  }
}

@media all and (max-width: 768px) {
  .featuresContainer {
    padding: 20px;
  }
}
