.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
}

.close-icon {
  width: 20px;
}

@media all and (max-width: 600px) {
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
  }

  .close-icon {
    width: 16px;
  }
}
