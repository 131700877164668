.container {
  display: flex;
  justify-content: left;
  /* background-color: crimson; */
}

.items {
  width: 30px;
  margin: 10px 20px 10px 0;
  cursor: pointer;
}
