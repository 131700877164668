.container {
  display: flex;
  height: 100%;
}

@media all and (max-width: 768px) {
  .container {
    display: block;
    text-align: center;
    width: 100%;
  }
}
