.flexContainer {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

.centerContainer {
  flex: 1;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px;
}

@media all and (max-width: 480px) {
  .centerContainer {
    padding: 0px;
    margin: 5px;
  }
}

.title {
  margin: 20px 0;
}

.titleDesc {
  margin: 0px;
  font-family: var(--font-primary);
  letter-spacing: 0.1em;
}

.logo {
  width: 40%;
}

@media all and (max-width: 480px) {
  .logo {
    margin-top: 50px;
  }
}
