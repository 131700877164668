.btn {
  padding: 14px 16px;
  /* Must handle that box shadow and border box property */
  background: linear-gradient(145deg, #f6ffff, #cfd8e0);
  box-shadow: -6px -2px 16px rgba(255, 255, 255, 0.4),
    6px 2px 16px rgba(19, 23, 27, 0.4);
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.8);
}
