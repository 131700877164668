.container {
  width: 400px;
  text-align: center;
  padding: 30px 0;
}

@media all and (max-width: 1280px) {
  .container {
    width: 300px;
    padding: 4px;
  }
}

@media all and (max-width: 860px) {
  .container {
    width: 300px;
  }
}

@media all and (max-width: 650px) {
  .container {
    width: 100%;
    padding: 0px;
  }
}

/* @media all and (max-width: 1024px) {
  .container {
    width: 100%;
  }
} */

.img {
  width: 70px;
}

.title {
  font-weight: lighter;
}

.desc {
  font-family: var(--font-primary);
  word-spacing: 0.1em;
  letter-spacing: 0.01em;
}
