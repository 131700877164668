.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  row-gap: 30px;
  column-gap: 30px;
  height: 120vh;
  color: white;
}

@media all and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    height: 120vh;
  }
}
