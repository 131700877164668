.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  row-gap: 50px;
  margin: 100px 0px;
}

@media all and (max-width: 1500px) {
  .container {
    margin: 40px 0px;
  }
}
