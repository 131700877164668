.hexContainer {
  margin: 50px;
  display: inline-block;
}

.wanda {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
  text-align: center;
}

/* Complete hex code */

.hexagon {
  position: relative;
  width: 200px;
  height: 115.47px;
  background: linear-gradient(0deg, #e3edf7, #e3edf7);
  margin: 57.74px 0;
  box-shadow: 6px 2px 16px rgba(136, 165, 191, 0.48),
    -6px -2px 16px rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.hexagon:before,
.hexagon:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 141.42px;
  height: 141.42px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background: linear-gradient(0deg, #e3edf7, #e3edf7);
  left: 29.2893px;
  box-shadow: 6px 2px 16px rgba(136, 165, 191, 0.48),
    -6px -2px 16px rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
}

.hexagon:before {
  top: -70.7107px;
}

.hexagon:after {
  bottom: -70.7107px;
}

/*cover up extra shadows*/
.hexagon span {
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 200px;
  height: 115.4701px;
  z-index: 2;
  background: inherit;
}

.h {
  background: -webkit-linear-gradient(315deg, rgb(182, 182, 182), #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.p {
  background: -webkit-linear-gradient(315deg, rgb(184, 184, 184), #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
