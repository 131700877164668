.input {
  background-color: #e3edf7;
  border: transparent;
  /* border: 1px solid
    linear-gradient(rgba(255, 255, 255, 1), rbga(58, 83, 111, 0.41)); */
  border-radius: 16px;
  box-shadow: inset -3px -3px 7px #ffffff,
    inset 2px 2px 5px rgba(136, 165, 191, 0.48);
  width: 300px;
  height: 40px;
  display: block;
  padding: 0 10px;
  font-family: var(--font-header);
  letter-spacing: 0.07em;
  margin-bottom: 40px;
  width: 100%;
  color: var(--color-primary);
}

.container {
  /* width: 50%; */
  padding: 0px 20px;
  margin: 0px 80px;
}

.box {
  padding: 0 20px;
  transform: translateY(-10%);
}

@media all and (max-width: 768px) {
  .container {
    width: 90%;
    padding: 0px 3px;
    margin: 0px auto;
  }
  .box {
    padding: 0 10px;
  }
}
