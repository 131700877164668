.secondary {
  font-family: var(--font-primary);
}

.primary {
  font-family: var(--font-header);
  color: var(--color-primary);
}

.bookNow {
  font-family: var(--font-header);
  color: var(--color-primary);
}

.header {
  position: fixed;
  right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: 20px;
  /* background-color: crimson; */
  color: var(--color-secondary);
  margin-top: 15px;
  z-index: 10;
}

.menuIcon {
  height: 15px;
  width: 15px;
  padding: 3px;
  top: 5px;
  position: relative;
}

.menuText {
  padding: 3px;
}

.lang {
  font-family: Catamaran;
  font-weight: 800;
  color: #704ee1;
  background: #a99dec;
}

.bookNow {
  color: aliceblue;
  background: #704ee1;
}

/* header list */
.headerList {
  display: flex;
  justify-content: flex-end;
  column-gap: 2em;
}

.headerLink {
  text-decoration: none;
  font-weight: normal;
  color: var(--color-primary);
}

.active {
  border-radius: 20px;
  background: #e3edf7;
  box-shadow: inset 5px 5px 10px #d1dae3, inset -5px -5px 10px #f5ffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.logo {
  width: 20%;
}
