/* Fonts */

@font-face {
  font-family: gilroy;
  src: url(../fonts/Gilroy-ExtraBold.otf);
  font-weight: bolder;
}

@font-face {
  font-family: gilroy-light;
  src: url(../fonts/Gilroy-Light.otf);
}

body {
  /* background: var(--bg); */
  font-family: var(--font-header);
  margin: 0px;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: var(--h1-size);
  color: var(--color-primary);
}

h1:before {
  display: block;
  content: ' ';
  margin-top: -100px;
  height: 100px;
  visibility: hidden;
}

h2 {
  font-size: var(--h2-size);
  color: var(--color-primary);
}

h3 {
  font-size: var(--h3-size);
  color: var(--color-primary);
}

h4 {
  font-size: var(--h4-size);
  color: var(--color-primary);
}

p {
  font-size: var(--p-size);
  color: var(--p-color);
  opacity: 0.6;
}

.title-desc {
  font-family: var(--font-primary);
}

.btn-default {
  padding: 14px 16px;
  /* Must handle that box shadow and border box property */
  background: linear-gradient(0deg, #e3edf7, #e3edf7);
  box-shadow: -6px -2px 16px rgba(255, 255, 255, 0.8),
    6px 2px 16px rgba(136, 165, 191, 0.48);
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

button:focus {
  outline: 0;
}

.btn-with-icon {
  padding: 10px 16px 14px 16px;
}

.icon {
  width: 24px;
  height: 24px;
  /* padding: 10px; */
}

.inline-block {
  display: inline-block;
}

#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}

.pointer {
  cursor: pointer;
}

.hover-btn {
  transition: 1s background ease-out;
}

.hover-btn:hover {
  background-color: var(--hover-btn);
  background: var(--hover-btn);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  /* color: #000000; color of your choosing */
  text-decoration: none;
  font-weight: normal;
}
